import { Typography } from "antd";
import { HTMLAttributes } from "react";

const EllipsisMiddle: React.FC<{ suffixCount: number; children: string } & HTMLAttributes<HTMLDivElement>> = (props) => {
    const { suffixCount, children, ...restProps } = props
    const start = children.slice(0, children.length - suffixCount).trim();
    const suffix = children.slice(-suffixCount).trim();
    return (
        <Typography.Text {...restProps} ellipsis={{ suffix }}>
            {start}
        </Typography.Text>
    );
};

export default EllipsisMiddle