

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}


export const retry = function <T>(fn: () => Promise<T>, ms = 1000, maxRetries?: number) {
    return new Promise<T>((resolve, reject) => {
        let retries = 0;
        const jitter = getRandomInt(1000);
        setTimeout(() => {
            fn().then(resolve).catch((e) => {
                setTimeout(() => {
                    console.log('Retrying failed promise...');
                    ++retries;
                    if (maxRetries !== undefined && retries == maxRetries) {
                        return reject(e);
                    }
                    retry(fn, ms, maxRetries).then(resolve);
                }, ms);
            });
        }, jitter);
    });
}
