import React from "react"

export type Contract = {
    [key in string]: {
        <T>(...args: any): {
            call: {
                (args?: any): Promise<T>
            },
            send: {
                (args?: {
                    from?: string,
                    gas?: number,
                    shouldPollResponse?: boolean,
                    feeLimit?: number,
                    callValue?: number,
                }): Promise<T>
            }
        }
    }
}
export type TronWeb = any

export function useTronBlockChanged(tronWeb: TronWeb, intervalTime = 5000) {
    const [blockNumber, setBlockNumber] = React.useState(0)
    const [blockIntervalId, setBlockIntervalId] = React.useState<NodeJS.Timer | null>(null)

    const checkBlock = React.useCallback(async () => {
        if (tronWeb && setBlockNumber) {
            const block = await tronWeb.trx.getCurrentBlock()
            const blockNumber: number = block.block_header?.raw_data?.number;
            if (blockNumber) {
                setBlockNumber(blockNumber)
            }
        }
    }, [tronWeb, setBlockNumber])


    React.useEffect(() => {
        if (blockIntervalId) {
            clearInterval(blockIntervalId)
            setBlockIntervalId(null)
        }
        const intervalId = setInterval(() => {
            checkBlock()
        }, intervalTime)
        setBlockIntervalId(intervalId)
        return () => {
            if (blockIntervalId) {
                clearInterval(blockIntervalId)
            }
        }
    }, [checkBlock, setBlockIntervalId])

    return blockNumber
}
export async function getTronContract(tronWeb: TronWeb, addr: string, abi?: any) {
    const contract = abi ? (tronWeb.contract(abi, addr)) : (await tronWeb.contract().at(addr))
    return contract as Contract
}
/**
 * 
 * @param tronWeb 
 * @param addr 
 * @param abi If the contract is a proxy contact, abi is needed.
 * @returns 
 */
export function useTronContract(tronWeb: TronWeb, addr: string, abi?: any) {
    const [contract, setContract] = React.useState<Contract | null>(null)

    const fetchContract = React.useCallback(async () => {
        if (tronWeb && addr && setContract && abi) {
            const contract = await getTronContract(tronWeb, addr, abi)
            setContract(contract)
        }
    }, [tronWeb, addr, abi, setContract])

    React.useEffect(() => {
        fetchContract()
    }, [fetchContract])

    return contract
}