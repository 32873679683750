import React from "react";
import { formatBase58Check, PauserAttribute, RatifierAttribute, TronContracts, Ratifiers } from "../configs";
import { TronNode } from "../connectors/tron-wallet";
import { retry } from "../utils";
import { Contract, getTronContract, TronWeb, useTronBlockChanged, useTronContract } from "./useTronHooks";
import BigNumber from 'bignumber.js'
import TokenControllerABI from '../abis/tron/TokenController.json'
import RegistryABI from '../abis/tron/Registry.json'
import TCNHABI from '../abis/tron/TCNH.json'
import asyncMap from 'p-map'
import delay from "delay";

export function useRatifierContract(tronWeb: TronWeb, node: TronNode.TronGrid | TronNode.Nile, account: string) {
    const blockNumber = useTronBlockChanged(tronWeb, 10000)
    const tokenController = useTronContract(tronWeb, TronContracts[node].tokenController, TokenControllerABI)
    const [tokenControllerConfig, setTokenControllerConfig] = React.useState<{
        ratifiedPool: {
            mint: BigNumber,
            limit: BigNumber,
            threshold: BigNumber,
        },
        refillRatifiers: string[],
        multiSigPool: {
            mint: BigNumber,
            limit: BigNumber,
            threshold: BigNumber,
        },
        instantPool: {
            mint: BigNumber,
            limit: BigNumber,
            threshold: BigNumber,
        },
        permissionPool: {
            mint: BigNumber,
            limit: BigNumber,
            threshold: BigNumber,
        }
    } | null>(null)
    const [otherConfig, setOtherConfig] = React.useState<{
        registry: Contract,
        token: Contract,
        tokenConfig: {
            decimals: number,
            name: string,
            symbol: string,
        },
        ratifiers: string[],
        owner: string,
        mintKey: string,
        registryOwner: string,
        registryAdmin: string,
        isRatifier: boolean | null,
        isPauser: boolean | null
    } | null>(null)
    const [isLoadError, setIsLoadError] = React.useState(false)

    const onTokenControllerChanged = React.useCallback(async () => {
        if (tokenController) {
            const ratifiedMintPool = await tokenController.ratifiedMintPool<string>().call()
            const multiSigMintPool = await tokenController.multiSigMintPool().call()
            const instantMintPool = await tokenController.instantMintPool<string>().call()
            const permissionMintPool = await tokenController.permissionMintPool<string>().call()
            const ratifiedMintLimit = await tokenController.ratifiedMintLimit().call()
            const multiSigMintLimit = await tokenController.multiSigMintLimit().call()
            await delay(1000);
            const instantMintLimit = await tokenController.instantMintLimit().call()
            const permissionMintLimit = await tokenController.permissionMintLimit().call()
            const ratifiedMintThreshold = await tokenController.ratifiedMintThreshold().call()
            const multiSigMintThreshold = await tokenController.multiSigMintThreshold().call()
            const instantMintThreshold = await tokenController.instantMintThreshold().call()
            const permissionMintThreshold = await tokenController.permissionMintThreshold().call()
            let refillRatifiers = await asyncMap(([0, 1]), async index => {
                return await tokenController.ratifiedPoolRefillApprovals<string>(index).call()
            })
            setTokenControllerConfig({
                refillRatifiers,
                ratifiedPool: {
                    mint: tronWeb.toBigNumber(ratifiedMintPool),
                    limit: tronWeb.toBigNumber(ratifiedMintLimit),
                    threshold: tronWeb.toBigNumber(ratifiedMintThreshold)
                },
                multiSigPool: {
                    mint: tronWeb.toBigNumber(multiSigMintPool),
                    limit: tronWeb.toBigNumber(multiSigMintLimit),
                    threshold: tronWeb.toBigNumber(multiSigMintThreshold)
                },
                instantPool: {
                    mint: tronWeb.toBigNumber(instantMintPool),
                    limit: tronWeb.toBigNumber(instantMintLimit),
                    threshold: tronWeb.toBigNumber(instantMintThreshold)
                },
                permissionPool: {
                    mint: tronWeb.toBigNumber(permissionMintPool),
                    limit: tronWeb.toBigNumber(permissionMintLimit),
                    threshold: tronWeb.toBigNumber(permissionMintThreshold)
                }
            })
        }
    }, [tokenController, setTokenControllerConfig, tronWeb])

    const requestOnce = React.useCallback(async () => {
        try {
            setIsLoadError(false)
            if (tokenController) {
                const tokenControllerOwner = await tokenController.owner<string>().call()
                const mintKey = await tokenController.mintKey<string>().call()
                const registryAdmin = await tokenController.registryAdmin<string>().call()
                const registryAddr = await tokenController.registry<string>().call()
                const tcnhTokenAddr = await tokenController.token<string>().call()
                const registry = await getTronContract(tronWeb, registryAddr, RegistryABI)
                const registryOwner = await registry.owner<string>().call()
                await delay(1000)
                const tcnhToken = await getTronContract(tronWeb, tcnhTokenAddr, TCNHABI)
                let isRatifier: boolean | null = null, isPauser: boolean | null = null
                if (account) {
                    isRatifier = await registry.hasAttribute<boolean>(account, RatifierAttribute).call()
                    isPauser = await registry.hasAttribute<boolean>(account, PauserAttribute).call()
                }
                const name = await tcnhToken.name<string>().call()
                const decimals = await tcnhToken.decimals<number>().call()
                const symbol = await tcnhToken.symbol<string>().call()
                setOtherConfig({
                    registry,
                    token: tcnhToken,
                    tokenConfig: {
                        decimals, name, symbol
                    },
                    owner: tronWeb.address.fromHex(tokenControllerOwner),
                    registryAdmin: tronWeb.address.fromHex(registryAdmin),
                    mintKey: tronWeb.address.fromHex(mintKey),
                    registryOwner: tronWeb.address.fromHex(registryOwner),
                    ratifiers: (Ratifiers[node] || []).map(refiller => formatBase58Check(tronWeb, refiller)),
                    isRatifier,
                    isPauser
                })
            }
        } catch (e) {
            setIsLoadError(true)
        }

    }, [tokenController, account, tronWeb, setOtherConfig, setIsLoadError])

    React.useEffect(() => {
        onTokenControllerChanged()
    }, [onTokenControllerChanged, blockNumber])

    React.useEffect(() => {
        requestOnce()
    }, [requestOnce])

    return {
        blockNumber,
        tokenController,
        isLoadError,
        requestData: requestOnce,
        config: {
            ...tokenControllerConfig,
            ...otherConfig
        }
    }
}


