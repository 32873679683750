import { Button, Input, message, Tooltip } from "antd"
import InputNumber, { InputNumberProps } from "./InputNumber"
import { EditOutlined, CheckOutlined } from '@ant-design/icons'
import React, { ChangeEvent, FormEvent } from "react"

const InputNumberWithConfirm: React.FC<InputNumberProps & { onConfirm: (value: string) => void, inputType: 'number' | 'text', confirmText?: string }> = function (props) {
    const { onChange, onInput, value, defaultValue, disabled, required, onConfirm, confirmText = 'Submit', inputType, ...restProps } = props
    const [inputVal, setInputVal] = React.useState(defaultValue || '')
    const _onChange = function (e: ChangeEvent<HTMLInputElement>) {
        let value = (e.target as HTMLInputElement).value
        setInputVal(value)
        onChange && onChange({ target: { value: inputVal } } as any as ChangeEvent<HTMLInputElement>)
    }
    React.useEffect(() => {
        if (value !== undefined) {
            _onChange({ target: { value } } as any as ChangeEvent<HTMLInputElement>)
        } else {
            _onChange({ target: { value: defaultValue || '' } } as any as ChangeEvent<HTMLInputElement>)
        }
    }, [value])
    const inputProps = {
        ...restProps,
        disabled,
        style: { width: 'calc(100% - 85px)' },
        value: inputVal,
        defaultValue,
        onInput: _onChange,
        onChange: _onChange
    }
    return (
        <Input.Group compact>
            {inputType === 'number' ? (
                <InputNumber {...inputProps} />
            ) : (
                <Input {...inputProps} />
            )}
            <Button
                type="primary"
                disabled={disabled}
                onClick={() => {
                    if (required && !inputVal) {
                        message.error('Please input the value')
                        return
                    }
                    onConfirm && onConfirm(inputVal)
                }}>{confirmText}</Button>
        </Input.Group>
    )
}
export default InputNumberWithConfirm