import { Button, Divider, Form, Modal, Tooltip } from "antd"
import { Rule } from "antd/es/form"
import startCase from "lodash/startCase"
import React from "react"
import isEqual from 'lodash/isEqual'
import BigNumber from "bignumber.js"
import InputNumber from "./InputNumber"
import confirm from "antd/es/modal/confirm"

const SetPoolForm: React.FC<{ disabled: boolean, decimals: number, instantPool: { initialValue: string, name: string, label: string, rules: Rule[] }, ratifiedPool: { initialValue: string, name: string, label: string, rules: Rule[] }, multiSigPool: { initialValue: string, name: string, label: string, rules: Rule[] }, onFinish?: (values: any) => Promise<any> }> = function ({ disabled, decimals, instantPool, ratifiedPool, multiSigPool, onFinish }) {
    const initialValues = { [instantPool.name]: instantPool.initialValue, [ratifiedPool.name]: ratifiedPool.initialValue, [multiSigPool.name]: multiSigPool.initialValue }
    const [values, setValues] = React.useState(initialValues)
    const isSumitDisabled = isEqual(initialValues, values) || !!Object.values(values).find(val => new BigNumber(val).lt(0))
    return (
        <>
            <Form
                disabled={disabled}
                initialValues={initialValues}
                requiredMark={false}
                labelCol={{ span: 3 }}
                colon={false}
                layout={'horizontal'}
                onFinish={async (values: any) => {
                    Modal.confirm({
                        title: 'Please check the values',
                        content: (
                            <ul style={{ padding: 0, margin: 0, lineHeight: 2 }}>
                                <li>
                                    {`Instant: ${values[instantPool.name]}`}
                                </li>
                                <li>
                                    {`Ratified: ${values[ratifiedPool.name]}`}
                                </li>
                                <li>
                                    {`MultiSig: ${values[multiSigPool.name]}`}
                                </li>
                            </ul>
                        ),
                        async onOk() {
                            await (onFinish && onFinish(values))
                        }
                    });
                }}>
                {[instantPool, ratifiedPool, multiSigPool].map((el, index) => (
                    <Form.Item key={el.name} name={el.name} label={startCase(el.name)} rules={el.rules}>
                        <InputNumber disabled={disabled} min={0} precision={decimals} value={values[index]} placeholder={`Currently is ${initialValues[el.name]}`} onChange={e => {
                            setValues({
                                ...values,
                                [el.name]: e.target.value
                            })
                        }} />
                    </Form.Item>
                ))}
                <Form.Item label={' '} >
                    <Button disabled={disabled || isSumitDisabled} type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default SetPoolForm