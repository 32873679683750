import * as React from 'react'
import { TronNode, useTronWallet } from '../../connectors/tron-wallet'
import { message, Layout, Col, Row, Typography, Button, Dropdown, List, Space, Divider, Form, Input, Radio, Collapse, Popover, Result, FloatButton, Drawer, Skeleton, Avatar, Progress, Tooltip, Tabs, Modal } from 'antd'
import InputNumber from '../../components/InputNumber'
import EllipsisMiddle from '../../components/EllipsisMiddle';
const { Header, Footer, Content } = Layout
const { Panel } = Collapse
/* eslint-disable import/first */
import styled from 'styled-components';
import Icon, { WalletOutlined, InfoCircleOutlined, SyncOutlined, EditOutlined, FileDoneOutlined, FileExcelOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './index.css'
import { useRatifierContract } from '../../hooks/useRatifierContracts';
import { LoadingOutlined } from '@ant-design/icons'
import BigNumber from 'bignumber.js'
import PoolPercent from '../../components/PoolPercent';
import ApproverAvatar from '../../components/ApproverAvatar';
import { BLACK_HOLE, formatBase58Check, isBlackHoleAddr, RatifierAttribute, TronContracts, TronScanPrefixs } from '../../configs';
import OwnerIcon from '../../icons/owner'
import ExternalIcon from '../../icons/external'
import DisconnnectIcon from '../../images/disconnect.png'
import TronlinkIcon from '../../images/tronlink.png'
import FillIcon from '../../icons/fill'
import NetworkErrorIcon from '../../icons/network-error'
import startCase from 'lodash/startCase'
import { Rule } from 'antd/es/form';
import { ethers } from 'ethers'
import concat from 'lodash/concat'
import { Observers } from '../../configs'
import { retry } from '../../utils';
import { List as ImmutableList } from 'immutable'
import InputNumberEdit from '../../components/InputNumberEdit';
import SetPoolForm from '../../components/SetPoolForm';
import InputNumberWithConfirm from '../../components/InputNumberConfirm';
import uniqBy from 'lodash/uniqBy';

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #145cd4;
    &:hover{
        background-color: #145cd4;
    }
  }
`;
interface TransactionInfo {
    blockNumber: number,
    blockTimeStamp: number,
    contractResult: string[],
    contract_address: string,
    fee: number,
    id: number,
    receipt?: {
        energy_fee: number,
        energy_usage_total: number,
        net_usage: number,
        result: string
    },
    resMessage: string,
    result: "SUCCESS" | "FAILED"
}


function getRoleColor(role: string) {
    let color = '#333333'
    if (role === 'owner') {
        color = '#31ADCE'
    } else if (role === 'ratifier') {
        color = '#2CD723'
    } else if (role === 'mintKey') {
        color = '#C7DB2D'
    } else if (role === 'registryAdmin') {
        color = '#F17D10'
    } else if (role === 'registryOwner') {
        color = '#e37fe1'
    } else if (role === 'observer') {
        color = '#e3e3e3'
    }
    return color
}

function getRoles(roles: string[], { owner, mintKey, registryAdmin, registryOwner, ratifiers }: { owner?: string, mintKey?: string, registryAdmin?: string, registryOwner?: string, ratifiers?: string[] }) {
    const owners = roles.filter(role => owner === role).map(el => ({ address: el, role: 'owner', name: 'Owner' }))
    const minters = roles.filter(role => mintKey === role).map(el => ({ address: el, role: 'mintKey', name: 'MintKey' }))
    const pausers = roles.filter(role => role === 'pauser').map(el => ({ address: '', role: 'pauser', name: 'Pauser' }))
    const registryAdmins = roles.filter(role => role === registryAdmin).map(el => ({ address: el, role: 'registryAdmin', name: 'Registry Admin' }))
    const registryOwners = roles.filter(role => role === registryOwner).map(el => ({ address: el, role: 'registryOwner', name: 'Registry Owner' }))
    let _ratifiers = roles.filter(role => !!ratifiers?.find(r => r === role))
    const _ratifiers2 = _ratifiers.map((el, index) => ({
        address: el, role: 'ratifier', name: `${_ratifiers.length > 1 ? ('Ratifier' + (index + 1)) : 'Ratifier'}`
    }))
    let allRoles = concat(owners, _ratifiers2, registryAdmins, registryOwners, minters, pausers)
    allRoles = uniqBy(allRoles, el => el.address.toLowerCase())
    return allRoles.map(el => {
        return { ...el, color: getRoleColor(el.role) }
    })
}

function Permissions({ roles, owner, mintKey, registryAdmin, registryOwner, ratifiers, node }: { roles: string[], owner?: string, mintKey?: string, registryAdmin?: string, registryOwner?: string, ratifiers?: string[], node: TronNode | null }) {
    const allRoles = getRoles(roles, { owner, mintKey, registryAdmin, registryOwner, ratifiers })
    return (
        <Space style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-start', minHeight: '55px' }}>
            <Typography.Text style={{ flex: 'none', whiteSpace: 'nowrap' }}>Permissions</Typography.Text>
            <Space style={{ display: 'flex', flexWrap: 'wrap', flex: 'auto' }}>
                {
                    allRoles.map((el, index) => {
                        return (
                            <Popover key={index} placement="top" title={el.name} content={el.address} trigger="hover">
                                <a target={'_blank'} href={el.address && node ? `${TronScanPrefixs[node]}/#/address/${el.address} ` : undefined}><ApproverAvatar style={{ fill: el.color }} /></a>
                            </Popover>
                        )
                    })
                }
            </Space>
        </Space>
    )
}

function Operators(el: { label: string, permissions: string[], isHeader?: boolean }) {
    return (
        <Space className='center'>
            <span className={el.isHeader ? 'header-4' : undefined}>{el.label}</span>
            <Popover placement='top' content={(
                <Space>
                    <Typography.Text>{el.permissions.length > 1 ? 'Operators' : 'Operator'} :</Typography.Text>
                    <span>
                        <Typography.Text>{startCase(el.permissions[0])}</Typography.Text>
                        <span> {el.permissions.length === 2 ? '&' : ''} </span>
                        <Typography.Text>{startCase(el.permissions[1])}</Typography.Text>
                    </span>
                </Space>
            )}>
                <InfoCircleOutlined />
            </Popover>
        </Space>
    )
}

const RatifierDashboard: React.FC<{}> = function () {
    const [isDrawerOpen, setDrawerOpen] = React.useState(false)
    const [txReceipList, setTxReceiptList] = React.useState<{ txId: string, success: boolean, message: string, loading: boolean, timeout: boolean, checked: boolean, meta: string[] }[]>([])
    const [mintType, setMintType] = React.useState('instant')
    const [ownerOperate, setOwnerOperate] = React.useState('limit')
    const [permissionOperate, setPermissionOperate] = React.useState('limit')
    const [formInstance] = Form.useForm()
    const { account, node, connect, disconnect, tronWeb } = useTronWallet()
    const { tokenController, isLoadError, requestData, config } = useRatifierContract(tronWeb, node === TronNode.Nile ? TronNode.Nile : TronNode.TronGrid, account)
    let { token, tokenConfig, owner, mintKey, ratifiers, refillRatifiers, ratifiedPool, multiSigPool, instantPool, permissionPool, isRatifier, isPauser, registry, registryAdmin, registryOwner } = config
    const [ratifyMintOperation, setRatifyMintOperation] = React.useState<{
        to: string,
        amount: string,
        approved: boolean,
        disabled: boolean
    } | null>(null)
    // refillers = ['TGoQNNJYmCqQE3mNAHABVV2rkUyGQLwSZ1', account, 'TGoQNNJYmCqQE3mNAHABVV2rkUyGQLwSZ1']
    const sign = async function (fn: { <T>(): Promise<string> }, meta: string[], success?: () => void) {
        try {
            const tx = await fn()
            txReceipList.push({
                txId: tx,
                message: '',
                loading: true,
                timeout: false,
                checked: false,
                success: true,
                meta
            })
            setTxReceiptList(txReceipList)
            message.success('Transaction has been submitted, the execution result will return later.')
            // Don't await
            retry(async () => {
                if (tronWeb) {
                    const txInfo: TransactionInfo = await tronWeb.trx.getTransactionInfo(tx)
                    if (!txInfo.receipt) {
                        throw new Error('Get the transaction receipt is timeout.')
                    }
                    return txInfo
                }
                return null
            }, 10000, 20).then((txInfo) => {
                if (txInfo && txInfo.receipt) {
                    const isSuccess = txInfo.receipt.result === 'SUCCESS'
                    const resMessage = txInfo.receipt.result
                    const contractCallResult = txInfo.contractResult.length > 0 ? txInfo.contractResult.map(result => result ? tronWeb.toUtf8(`0x${result.slice(8)}`) : 'Failed').join(';') : ''
                    if (isSuccess) {
                        message.success('Transaction has been executed successfully.')
                        if (success) {
                            success()
                        }
                    } else {
                        message.error('Transaction execute failed, Please check the result.')
                    }
                    const index = txReceipList.findIndex(el => el.txId === tx)
                    txReceipList[index] = {
                        txId: tx,
                        success: isSuccess,
                        message: isSuccess ? 'Success' : contractCallResult || resMessage,
                        loading: false,
                        timeout: false,
                        checked: false,
                        meta
                    }
                    setTxReceiptList(txReceipList)
                }
            }).catch(e => {
                const index = txReceipList.findIndex(el => el.txId === tx)
                txReceipList[index] = {
                    txId: tx,
                    success: false,
                    message: (e as Error).message,
                    loading: false,
                    timeout: true,
                    checked: false,
                    meta
                }
                setTxReceiptList(txReceipList)
            })
        } catch (err) {
            const e = err as Error
            message.error(e.message ? e.message : String(e as any))
        }
    }

    let isTargetTronNode = node === process.env.REACT_APP_TRON_NODE
    let isObserver = !!Observers.find(o => o.toLowerCase() === account.toLowerCase())
    let isRegistryAdmin = registryAdmin?.toLowerCase() === account.toLowerCase()
    let isRegistryOwner = registryOwner?.toLowerCase() === account.toLowerCase()
    const isMintKey = mintKey?.toLowerCase() === account?.toLowerCase()
    let isOwner = owner ? owner.toLowerCase() === account.toLowerCase() : undefined
    isOwner = process.env.NODE_ENV === 'development' ? true : isOwner
    const decimals = tokenConfig?.decimals || 18
    const isUser = isOwner || isRatifier || isPauser || isRegistryAdmin || isMintKey || isObserver || isRegistryOwner
    const isLoading = !(tokenConfig && config && (typeof isRatifier === 'boolean') && (typeof isPauser === 'boolean') && (typeof owner === 'string') && (typeof registryOwner === 'string'))
    const refilledProgress = tronWeb ? (refillRatifiers || [])?.filter(r => !isBlackHoleAddr(tronWeb, r)).length : 0
    const isValidateRatifyMintOperation = ratifyMintOperation ? (!isBlackHoleAddr(tronWeb, ratifyMintOperation.to) && !new BigNumber(ratifyMintOperation.amount).eq(0)) : false

    React.useEffect(() => {
        let type = mintType
        if (isOwner) {
            type = 'owner'
        } else if (isRatifier) {
            type = 'ratify'
        } else {
            type = 'instant'
        }
        setMintType(type)
        formInstance.setFieldValue('type', type)
    }, [isRatifier, isOwner])

    const rest = {
        owner, mintKey, registryAdmin, ratifiers, node, registryOwner
    }

    const data = [
        {
            title: 'MultiSig Pool',
            description: (owner ? <Permissions roles={[owner]} {...rest} /> : '--'),
            content: (<PoolPercent decimals={decimals} pool={multiSigPool} />),
            disabled: !isOwner,
            btnText: 'Refill',
            onClick: async () => {
                if (tokenController) {
                    await sign(() => tokenController.refillMultiSigMintPool<string>().send({
                        from: account,
                        gas: 300000
                    }), ['Refill the multiSig mint pool'])
                }
            }
        },
        {
            title: 'Ratified Pool',
            description: (owner && ratifiers ? <Permissions roles={[owner, ...ratifiers]}  {...rest} /> : '--'),
            content: (<PoolPercent decimals={decimals} pool={ratifiedPool} />),
            disabled: !isOwner && !isRatifier,
            btnText: `Refill${refilledProgress > 0 ? ` (${(refilledProgress) + '/3'})` : ''}`,
            onClick: async () => {
                if (tokenController) {
                    await sign(() => tokenController.refillRatifiedMintPool<string>().send({
                        from: account,
                        gas: 300000
                    }), ['Refill the ratified mint pool'])
                }
            }
        },
        {
            title: 'Instant Pool',
            description: (owner && ratifiers ? <Permissions roles={[owner, ...ratifiers]}  {...rest} /> : '--'),
            content: (<PoolPercent decimals={decimals} pool={instantPool} />),
            disabled: !isOwner && !isRatifier,
            btnText: 'Refill',
            onClick: async () => {
                if (tokenController) {
                    await sign(() => tokenController.refillInstantMintPool<string>().send({
                        from: account,
                        gas: 300000
                    }), ['Refill the instant mint pool'])
                }
            }
        },
        {
            title: 'Permission Pool',
            description: (owner ? <Permissions roles={[owner]}  {...rest} /> : '--'),
            content: (<PoolPercent decimals={decimals} pool={permissionPool} />),
            disabled: !isOwner,
            btnText: 'Refill',
            onClick: async () => {
                if (tokenController) {
                    await sign(() => tokenController.refillPermissionPool<string>().send({
                        from: account,
                        gas: 300000
                    }), ['Refill the permission mint pool'])
                }
            }
        }
    ]

    const addrRules: Rule[] = [
        {
            required: true,
            message: "${name} is required."
        },
        {

            validator: async function (rule, value) {
                if (!(tronWeb && tronWeb.isAddress(value))) {
                    throw new Error('Invalid address.')
                }
            }
        },
    ]

    const amountMax = React.useMemo(() => {
        if (mintType === 'instant' && instantPool) {
            return instantPool.mint
        } else if (mintType === 'ratify' && !isOwner && ratifiedPool) {
            return ratifiedPool.mint
        } else {
            // return multiSigPool.mint
            return undefined
        }
    }, [mintType, instantPool, ratifiedPool])

    const amountRules: Rule[] = [
        {
            required: true,
            message: "${name} is required."
        },
        {

            validator: async function (rule, value) {
                if (new BigNumber(value).lte(0)) {
                    throw new Error('Amount should be greater than 0.')
                } else if (amountMax) {
                    const max = ethers.utils.formatUnits(amountMax.toFixed())
                    if (new BigNumber(value).gt(max, decimals)) {
                        throw new Error(`Amount should be less than ${max}.`)
                    }
                }
            }
        },
    ]

    const limitAndThresholdRules: Rule[] = [
        {
            required: true,
            message: "${name} is required."
        }
    ]

    const opIndexRules: Rule[] = [
        {
            required: true,
            message: '${name} is required.'
        },
        {
            validator: async (rule, value, callback) => {
                if (tokenController) {
                    const mintOperations = await tokenController.mintOperationCount<number>().call()
                    if (tronWeb.toBigNumber(mintOperations).lte(value)) {
                        throw new Error('Op index should be less than ' + mintOperations)
                    }
                }
            },
        }
    ]

    return (
        <Layout className='fill-height' style={{ height: '100%' }}>
            <Header>
                <Row wrap={false} align="middle">
                    <Col flex="auto">
                        <Typography.Title level={2} style={{ color: '#ffffff', marginBottom: 0 }}>
                            TCNH Internal Dashboard
                        </Typography.Title>
                    </Col>
                    <Col flex="none">
                        {account ? (
                            <Space style={{ display: 'flex', alignItems: 'center', color: '#ffffff' }} className='center'>
                                <Dropdown
                                    trigger={['click']}
                                    overlayStyle={{ minWidth: 'auto!important' }}
                                    menu={{
                                        items: [{
                                            key: '4',
                                            label: (
                                                <Space onClick={async () => await disconnect()}>
                                                    <span>Disconnect</span>
                                                    <img src={DisconnnectIcon} style={{ height: '1em' }} />
                                                </Space>
                                            ),
                                        }]
                                    }}>
                                    <Space>
                                        <EllipsisMiddle suffixCount={10} style={{ maxWidth: '200px', color: 'inherit', cursor: 'pointer' }}>
                                            {account}
                                        </EllipsisMiddle>
                                        <span>|</span>
                                        <span>{node === TronNode.TronGrid ? 'Mainnet' : node}</span>
                                    </Space>
                                </Dropdown>
                                <a target={'_blank'} href={node ? `${TronScanPrefixs[node]}/#/address/${account}` : undefined} style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                                    <Icon component={ExternalIcon} style={{ color: '#ffffff' }} />
                                </a>
                            </Space>
                        ) : (
                            <ButtonContainer>
                                <Button type='primary' onClick={async () => {
                                    try {
                                        await connect()
                                    } catch (e) {
                                        message.error((e as Error).message)
                                    }
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Connect</span>
                                        <img src={TronlinkIcon} style={{ height: '1em', marginLeft: '5px' }} />
                                    </div>
                                </Button>
                            </ButtonContainer>
                        )}

                    </Col>
                </Row>
            </Header>
            <Content>
                {account ? (
                    isTargetTronNode ? (
                        isLoadError ? (
                            <Result
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%', flex: 'auto' }}
                                title={<Typography.Title level={4}>Request failed.</Typography.Title>}
                                extra={(<Space style={{ fontSize: '16px' }}>Please <Button size='small' onClick={requestData}>reload</Button> the data.</Space>)} />
                        ) : isLoading ? (
                            <Result
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%', flex: 'auto' }}
                                icon={<LoadingOutlined />}
                            />
                        ) : (
                            isUser ? (
                                <>
                                    <div className='panel' style={{ padding: '20px', marginTop: '20px' }}>
                                        <h4 className='header-4'>
                                            User Role & Permissions
                                        </h4>
                                        <Space size={'large'} style={{ margin: '10px 0' }}>
                                            {isOwner && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('owner'), width: '20px', height: '20px' }} /><span className='role-name'>Owner</span></Space>)}
                                            {isRatifier && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('ratifier'), width: '20px', height: '20px' }} /><span className='role-name'>Ratifier</span></Space>)}
                                            {isPauser && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('pauser'), width: '20px', height: '20px' }} /><span className='role-name'>Pauser</span></Space>)}
                                            {isRegistryAdmin && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('registryAdmin'), width: '20px', height: '20px' }} /><span className='role-name'>Registry Admin</span></Space>)}
                                            {isRegistryOwner && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('registryOwner'), width: '20px', height: '20px' }} /><span className='role-name'>Registry Owner</span></Space>)}
                                            {isObserver && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('observer'), width: '20px', height: '20px' }} /><span className='role-name'>Observer</span></Space>)}
                                            {owner === undefined ? '--' : (isMintKey && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('mintKey'), width: '20px', height: '20px' }} /><span className='role-name'>Mint Key</span></Space>))}
                                        </Space>
                                    </div>
                                    <div className='panel'>
                                        <List
                                            className="refills-list"
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={data}
                                            renderItem={(item, index) => (
                                                <List.Item
                                                    key={item.title}
                                                    actions={[
                                                        <Button
                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                            ghost
                                                            type="primary"
                                                            shape="round"
                                                            icon={<Icon component={FillIcon} style={{ width: '15px', height: '15px' }} />}
                                                            disabled={item.disabled}
                                                            onClick={item.onClick}>
                                                            {item.btnText}
                                                        </Button>
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        title={item.title}
                                                        description={item.description}
                                                    />
                                                    <div>{item.content}</div>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                    <div className='tabs-container' style={{ padding: '20px' }}>
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    key: '1',
                                                    label: `Mint`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4 className='header-4'>
                                                                    Mint Token
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    className={mintType === 'ratify' && ratifyMintOperation ? 'ratify' : undefined}
                                                                    form={formInstance}
                                                                    disabled={!isOwner && !isRatifier && !isMintKey && !isObserver}
                                                                    initialValues={{ type: mintType }}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            const amount = ethers.utils.parseUnits(values.amount, decimals)
                                                                            if (values.type === 'instant') {
                                                                                await sign(() => tokenController.instantMint<string>(values.receipt, amount).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Instant mint', `Receipt: ${values.receipt}`, `Amount: ${values.amount}`])
                                                                            } else if (values.type === 'request') {
                                                                                await sign(() => tokenController.requestMint<string>(values.receipt, amount).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Request mint', `Receipt: ${values.receipt}`, `Amount: ${values.amount}`])
                                                                            } else if (values.type === 'ratify') {
                                                                                await sign(() => tokenController.ratifyMint<string>(values.opIndex, values.receipt, amount).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Ratify mint', `Op Index: ${values.opIndex}`, `Receipt: ${values.receipt}`, `Amount: ${values.amount}`])
                                                                            } else if (values.type === 'owner') {
                                                                                await sign(() => tokenController.ownerMint<string>(ethers.utils.parseUnits(values.amount, decimals)).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Owner mint', `Receipt: ${owner}`, `Amount: ${values.amount}`])
                                                                            }
                                                                        }
                                                                    }}>
                                                                    <Form.Item name="type" label={' '} rules={[{ required: true, message: 'Please select the mint type.' }]}>
                                                                        <Radio.Group
                                                                            buttonStyle={'solid'}
                                                                            options={[
                                                                                { value: 'owner', disabled: !isOwner && !isObserver, permissions: ['owner'] },
                                                                                { value: 'instant', disabled: !isOwner && !isMintKey && !isObserver, permissions: ['owner', 'mint key'] },
                                                                                { value: 'request', disabled: !isOwner && !isMintKey && !isObserver, permissions: ['owner', 'mint key'] },
                                                                                { value: 'ratify', disabled: !isOwner && !isRatifier && !isObserver, permissions: ['owner', 'ratifiers'] },
                                                                            ].map(el => {
                                                                                return {
                                                                                    label: (
                                                                                        <Operators key={el.value} label={startCase(`${el.value} mint`)} permissions={el.permissions} />
                                                                                    ),
                                                                                    value: el.value,
                                                                                    disabled: el.disabled
                                                                                }
                                                                            })}
                                                                            optionType="button"
                                                                            onChange={(e) => {
                                                                                setMintType(e.target.value)
                                                                                formInstance.resetFields(['amount', 'receipt', 'opIndex'])
                                                                                setRatifyMintOperation(null)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                    {mintType === 'owner' && (
                                                                        <Form.Item label="Tips: ">
                                                                            <Typography.Text>
                                                                                Owner can mint unlimited amount of TCNH to owner address - {owner}.
                                                                            </Typography.Text>
                                                                        </Form.Item>
                                                                    )}
                                                                    {mintType === 'ratify' && (
                                                                        <Form.Item name="opIndex" label="OP Index" rules={opIndexRules}>
                                                                            <InputNumber min={0} precision={0} style={{ width: '100%' }} disabled={!!(mintType === 'ratify' && ratifyMintOperation)} />
                                                                        </Form.Item>
                                                                    )}
                                                                    {mintType !== 'owner' && (mintType !== 'ratify' || isValidateRatifyMintOperation) && (
                                                                        <Form.Item name="receipt" label="Receipt" rules={addrRules}>
                                                                            <Input placeholder={`Receipt address`} disabled={!isOwner && !isRatifier && !isMintKey || mintType === 'ratify'} />
                                                                        </Form.Item>
                                                                    )}
                                                                    {(mintType !== 'ratify' || isValidateRatifyMintOperation) && (
                                                                        <Form.Item name="amount" label="Amount" rules={amountRules}>
                                                                            <InputNumber precision={decimals} style={{ width: '100%' }} placeholder={amountMax && `Maximum amount of mints is ¥ ${ethers.utils.formatUnits(amountMax.toFixed())}`} disabled={!isOwner && !isRatifier && !isMintKey || mintType === 'ratify'} />
                                                                        </Form.Item>
                                                                    )}
                                                                    {(mintType === 'ratify' && ratifyMintOperation && !isValidateRatifyMintOperation) && (
                                                                        <Form.Item label=" ">
                                                                            <Typography.Text type="success">The mint operation of the op index has been completed.</Typography.Text>
                                                                        </Form.Item>
                                                                    )}
                                                                    <Form.Item label={' '} >
                                                                        <Space>
                                                                            {((isOwner || isRatifier || isMintKey) && (mintType !== 'ratify' || isValidateRatifyMintOperation)) && (
                                                                                <Button type="primary" htmlType="submit">Submit</Button>
                                                                            )}
                                                                            {mintType === 'ratify' && (
                                                                                ratifyMintOperation ? (
                                                                                    <Button
                                                                                        onClick={async () => {
                                                                                            setRatifyMintOperation(null)
                                                                                            formInstance.resetFields(['amount', 'receipt', 'opIndex'])
                                                                                        }}>
                                                                                        Clear
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        type="primary"
                                                                                        onClick={async () => {
                                                                                            const opIndex = formInstance.getFieldValue('opIndex')
                                                                                            if (new BigNumber(opIndex).isNaN()) {
                                                                                                message.error('OP Index is incorrect.')
                                                                                                return
                                                                                            }
                                                                                            if (tokenController && opIndex) {
                                                                                                try {
                                                                                                    await formInstance.validateFields(['opIndex'])
                                                                                                    const { value, to } = await tokenController.mintOperations<{ value: number, to: string }>(opIndex).call()
                                                                                                    formInstance.setFieldValue('amount', ethers.utils.formatUnits(value, decimals))
                                                                                                    formInstance.setFieldValue('receipt', formatBase58Check(tronWeb, to))
                                                                                                    setRatifyMintOperation({
                                                                                                        amount: ethers.utils.formatUnits(value, decimals),
                                                                                                        to: formatBase58Check(tronWeb, to),
                                                                                                        approved: false, // It is not used now.
                                                                                                        disabled: true
                                                                                                    })
                                                                                                } catch (e) {
                                                                                                    message.error(e as any as string)
                                                                                                    return
                                                                                                }
                                                                                            }
                                                                                        }}>
                                                                                        Query
                                                                                    </Button>
                                                                                )
                                                                            )}
                                                                        </Space>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h3>
                                                                    <Operators label={'Special Mint'} permissions={['Anyone']} isHeader />
                                                                </h3>
                                                                <Typography.Text>
                                                                    If a mint is ratified while there is not enough in the pool, it will not finalize. After refilling the pool,
                                                                    you can finalize the mint.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            await sign(() => tokenController.finalizeMint<string>(values.opIndex).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Finalize mint', `Op Index: ${values.opIndex}`])
                                                                        }
                                                                    }}>
                                                                    <Form.Item name="opIndex" label="OP Index" rules={opIndexRules}>
                                                                        <InputNumber min={0} precision={0} style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                    <Form.Item label={' '} >
                                                                        <Button type="primary" htmlType="submit">Finalize Mint</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: '2',
                                                    label: `Pool`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <Operators label={'Owner Permissions'} permissions={['Owner']} isHeader />
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner && !isObserver}
                                                                    initialValues={{ type: ownerOperate }}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 3 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}>
                                                                    <Form.Item name="type" label={' '}>
                                                                        <Radio.Group
                                                                            buttonStyle={'solid'}
                                                                            options={[
                                                                                { value: 'limit', permissions: ['owner'] },
                                                                                { value: 'threshold', permissions: ['owner'] },
                                                                            ].map(el => {
                                                                                return {
                                                                                    label: (
                                                                                        <Operators key={el.value} label={startCase(`Set ${el.value}`)} permissions={el.permissions} />
                                                                                    ),
                                                                                    value: el.value
                                                                                }
                                                                            })}
                                                                            optionType="button"
                                                                            onChange={(e) => {
                                                                                setOwnerOperate(e.target.value)
                                                                                // formInstance.resetFields(['amount', 'receipt', 'opIndex'])
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </Form>
                                                                {['limit', 'threshold'].map(key => {
                                                                    if (ownerOperate === key && instantPool && ratifiedPool && multiSigPool && permissionPool) {
                                                                        return (
                                                                            <SetPoolForm
                                                                                key={key}
                                                                                disabled={!isOwner}
                                                                                decimals={decimals}
                                                                                instantPool={{ initialValue: (key === 'limit' ? instantPool.limit : instantPool.threshold).div(new BigNumber(10).pow(decimals)).toFixed(), name: 'instant', label: 'Instant', rules: limitAndThresholdRules }}
                                                                                ratifiedPool={{ initialValue: (key === 'limit' ? ratifiedPool.limit : ratifiedPool.threshold).div(new BigNumber(10).pow(decimals)).toFixed(), name: 'ratified', label: 'Ratified', rules: limitAndThresholdRules }}
                                                                                multiSigPool={{ initialValue: (key === 'limit' ? multiSigPool.limit : multiSigPool.threshold).div(new BigNumber(10).pow(decimals)).toFixed(), name: 'multiSig', label: 'MultiSig', rules: limitAndThresholdRules }}
                                                                                onFinish={async (values: { instant: string, ratified: string, multiSig: string }) => {
                                                                                    if (tokenController) {
                                                                                        if (new BigNumber(values.instant).gt(values.ratified) || new BigNumber(values.ratified).gt(values.multiSig)) {
                                                                                            message.error('instant should <= ratified & ratified should <= multiSig')
                                                                                            return
                                                                                        }
                                                                                        const instant = ethers.utils.parseUnits(values.instant, decimals)
                                                                                        const ratified = ethers.utils.parseUnits(values.ratified, decimals)
                                                                                        const multiSig = ethers.utils.parseUnits(values.multiSig, decimals)
                                                                                        if (ownerOperate === 'limit') {
                                                                                            await sign(() => tokenController.setMintLimits<string>(instant, ratified, multiSig).send({
                                                                                                from: account,
                                                                                                gas: 300000
                                                                                            }), ['Set mint limits', `Instant: ${values.instant}`, `Ratified: ${values.ratified}`, `MultiSign: ${values.multiSig}`])
                                                                                        } else {
                                                                                            await sign(() => tokenController.setMintThresholds<string>(instant, ratified, multiSig).send({
                                                                                                from: account,
                                                                                                gas: 300000
                                                                                            }), ['Set mint thresholds', `Instant: ${values.instant}`, `Ratified: ${values.ratified}`, `MultiSign: ${values.multiSig}`])
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    return null
                                                                })}
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <Operators label={'Permission Pool'} permissions={['Owner']} isHeader />
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    initialValues={{ type: permissionOperate }}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 3 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}>
                                                                    {permissionPool && (
                                                                        <Form.Item label={startCase('Mint Limit')}>
                                                                            <InputNumberWithConfirm required inputType='number' min={0} precision={decimals} placeholder={`Currently is ${permissionPool.limit.div(new BigNumber(10).pow(decimals)).toFixed()}`} onConfirm={async (value) => {
                                                                                if (tokenController) {
                                                                                    const limit = ethers.utils.parseUnits(value, decimals)
                                                                                    await sign(() => tokenController.setPermissionMintLimit<string>(limit).send({
                                                                                        from: account,
                                                                                        gas: 300000
                                                                                    }), ['Set permission mint limit', `Limit: ${value}`])
                                                                                }
                                                                            }} />
                                                                        </Form.Item>
                                                                    )}
                                                                    {permissionPool && (
                                                                        <Form.Item label={startCase('Mint Threshold')} rules={limitAndThresholdRules}>
                                                                            <InputNumberWithConfirm required inputType='number' min={0} precision={decimals} placeholder={`Currently is ${permissionPool.threshold.div(new BigNumber(10).pow(decimals)).toFixed()}`} onConfirm={async (value) => {
                                                                                if (tokenController) {
                                                                                    const threshold = ethers.utils.parseUnits(value, decimals)
                                                                                    await sign(() => tokenController.setPermissionMintThreshold<string>(threshold).send({
                                                                                        from: account,
                                                                                        gas: 300000
                                                                                    }), ['Set permission mint threshold', `Threshold: ${value}`])
                                                                                }
                                                                            }} />
                                                                        </Form.Item>
                                                                    )}
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: '3',
                                                    label: `Role`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set Ratifiers'} permissions={['RegistryOwner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isRegistryOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    onFinish={async (values) => {
                                                                        if (registry) {
                                                                            await sign(() => registry!.setAttributeValue<string>(values.address, RatifierAttribute, values.isRatifier ? 1 : 0).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), [values.isRatifier ? 'Add Ratifier' : 'Remove Ratifier', `Ratifier: ${values.address}`], async () => {
                                                                                // when execute success
                                                                                await requestData()
                                                                            });
                                                                        }
                                                                    }}>
                                                                    <Form.Item label="Address" name="address" rules={addrRules}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item name="isRatifier" label="Is Ratifier" rules={[{ required: true, message: 'Please check the is ratifier field.' }]}>
                                                                        <Radio.Group>
                                                                            <Radio value={true}>Yes</Radio>
                                                                            <Radio value={false}>No</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item label={' '}>
                                                                        <Button type="primary" htmlType="submit">Sumbit</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set Permission Admin'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}>
                                                                    <Form.Item label={'Address'}>
                                                                        <InputNumberWithConfirm required inputType='text' onConfirm={async (value) => {
                                                                            if (!tronWeb.isAddress(value)) {
                                                                                message.error('Invalidate address')
                                                                                return
                                                                            }
                                                                            if (tokenController) {
                                                                                await sign(() => tokenController.setPermissionAdmin<string>(value).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Set New Permission Admin', `New Permission Admin: ${value}`])
                                                                            }
                                                                        }} />
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set MintKey'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}>
                                                                    <Form.Item label={'Address'}>
                                                                        <InputNumberWithConfirm required inputType='text' onConfirm={async (value) => {
                                                                            if (!tronWeb.isAddress(value)) {
                                                                                message.error('Invalidate address')
                                                                                return
                                                                            }
                                                                            if (tokenController) {
                                                                                await sign(() => tokenController.transferMintKey<string>(value).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Set Mint Key', `New Mint Key: ${value}`])
                                                                            }
                                                                        }} />
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set Registry Admin'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}>
                                                                    <Form.Item label={'Address'}>
                                                                        <InputNumberWithConfirm required inputType='text' onConfirm={async (value) => {
                                                                            if (!tronWeb.isAddress(value)) {
                                                                                message.error('Invalidate address')
                                                                                return
                                                                            }
                                                                            if (tokenController) {
                                                                                await sign(() => tokenController.setRegistryAdmin<string>(value).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Set Registry Admin', `New Registry Admin: ${value}`])
                                                                            }
                                                                        }} />
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set CanBurn'} permissions={['Owner', 'Registry Admin']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner && !isRegistryAdmin}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController && token) {
                                                                            const canBurn = await token.canBurn(values.address).call()
                                                                            if (canBurn === values.canBurn) {
                                                                                message.error(`The address ${values.address} has already been set.`)
                                                                                return
                                                                            }
                                                                            await sign(() => tokenController.setCanBurn<string>(values.address, values.canBurn).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Set can burn', `Address: ${values.address}`, `Can burn: ${values.canBurn}`])
                                                                        }
                                                                    }}
                                                                    colon={false}>
                                                                    <Form.Item label="Address" name="address" rules={addrRules}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item name="canBurn" label="Can Burn" rules={[{ required: true, message: 'Please check the can burn field.' }]}>
                                                                        <Radio.Group>
                                                                            <Radio value={true}>Yes</Radio>
                                                                            <Radio value={false}>No</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item label={' '}>
                                                                        <Button type="primary" htmlType="submit">Sumbit</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set Blacklist'} permissions={['Owner', 'Registry Admin']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner && !isRegistryAdmin}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            await sign(() => tokenController.setBlacklisted<string>(values.address, values.blacklist).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Set blacklist', `Address: ${values.address}`, `Is blacklist: ${values.blacklist}`])
                                                                        }
                                                                    }}
                                                                    colon={false}>
                                                                    <>
                                                                        <Form.Item label="Address" name="address" rules={addrRules}>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item name="blacklist" label="Blacklist" rules={[{ required: true, message: 'Please check the can burn field.' }]}>
                                                                            <Radio.Group>
                                                                                <Radio value={true}>Add</Radio>
                                                                                <Radio value={false}>Remove</Radio>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                        <Form.Item label={' '}>
                                                                            <Button type="primary" htmlType="submit">Sumbit</Button>
                                                                        </Form.Item>
                                                                    </>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: '4',
                                                    label: `Emergency`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={' Pause One Mint'} permissions={['Owner', 'Pauser']} isHeader />
                                                                </h4>
                                                                <Typography.Text>
                                                                    If a request mint is invalid, pauser or owner can pause it, which prevents anyone from ratifying it. Only owner can unpause a paused request mint.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner && !isPauser}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            await sign(() => tokenController.pauseMint<string>(values.opIndex).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Pause mint', `Op index: ${values.opIndex}`])
                                                                        }
                                                                    }}>
                                                                    <Form.Item name="opIndex" label="OP Index" rules={opIndexRules}>
                                                                        <InputNumber min={0} precision={0} style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                    <Form.Item label={' '} >
                                                                        <Button type="primary" htmlType='submit' danger>Pause Mint</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Pause All Mints'} permissions={['Owner', 'Pauser']} isHeader />
                                                                </h4>
                                                                <Typography.Text>
                                                                    If there is a continuous stream of invalid mint requests, pauser or owner can pause all of them. Only owner can unpause mint.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner && !isPauser}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            await sign(() => tokenController.pauseMints<string>().send({
                                                                                from: account,
                                                                                gas: 200000
                                                                            }), ['Pause all mints'])
                                                                        }
                                                                    }}>
                                                                    <Form.Item label={' '} >
                                                                        <Button type="primary" htmlType='submit' danger>Pause All Mints</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                            ]} />
                                    </div>
                                </>
                            ) : (
                                <Result
                                    className='result'
                                    status="warning"
                                    title="You are not a authorized user."
                                />
                            )
                        )
                    ) : (
                        <Result
                            className='result'
                            icon={<Icon component={NetworkErrorIcon} style={{ fontSize: '50px', marginBottom: '20px' }} />}
                            extra={
                                <Typography.Title level={3}>Please switch the network to {(process.env.REACT_APP_TRON_NODE || '').toLowerCase()}.</Typography.Title>
                            }
                        />
                    )
                ) : (
                    <Result
                        className='result'
                        icon={<WalletOutlined style={{ fontSize: '50px', marginBottom: '20px' }} />}
                        extra={
                            <Space direction='vertical'>
                                <Typography.Title level={3}>Please connect with authorized tronlink wallets.</Typography.Title>
                                {node && (
                                    <Skeleton active loading={!owner} title={false} avatar={false} paragraph={{ rows: 1, width: '100%' }}>
                                        <Typography.Text>Contract is owned by <Typography.Link target={'_blank'} href={`${TronScanPrefixs[node]}/#/address/${owner || Observers[0]}`}>{owner || Observers[0]}</Typography.Link></Typography.Text>
                                    </Skeleton>
                                )}
                            </Space>
                        }
                    />
                )}
            </Content>
            <Footer>
                <div className='footer'>
                    © 2023 TCNH. All rights reserved.
                </div>
            </Footer>
            {txReceipList.length > 0 && (
                <FloatButton
                    icon={txReceipList.find(tx => tx.loading) ? <SyncOutlined spin style={{ color: '#145cd4', fontSize: '20px' }} /> : (txReceipList.find(tx => !tx.success && !tx.checked) ? <FileExcelOutlined style={{ color: '#eb2f96', fontSize: '20px' }} /> : <FileDoneOutlined style={{ color: '#52c41a', fontSize: '20px' }} />)}
                    style={{ bottom: '100px', width: '60px', height: "60px" }}
                    onClick={() => { setDrawerOpen(true) }}
                    tooltip={<div>{txReceipList.find(tx => tx.loading) ? 'Pending transactions' : txReceipList.find(tx => !tx.success && !tx.checked) ? 'Some transactions executed failed.' : 'All Transactions has been checked.'}</div>} />
            )}
            <Drawer
                title="Transaction Receipts"
                placement={'right'}
                closable={true}
                onClose={() => setDrawerOpen(false)}
                open={isDrawerOpen}
            >
                <List
                    itemLayout="vertical"
                    dataSource={txReceipList.reverse()}
                    renderItem={(item, index) => (
                        <List.Item
                            key={item.txId}
                            style={{ padding: '12px 0 0 0' }}>
                            <List.Item.Meta
                                avatar={(<Avatar>{txReceipList.length - index}</Avatar>)}
                                title={<Typography.Text>Tx: <Typography.Link href={node ? `${TronScanPrefixs[node]}/#/transaction/${item.txId}` : undefined} target={'_blank'}>{item.txId.slice(0, 23) + '...'}</Typography.Link></Typography.Text>}
                                description={
                                    <Space direction="vertical" style={{ marginBottom: '10px', display: 'flex' }}>
                                        <div style={{ lineHeight: 1.5 }}>{item.meta.map(el => (<div>{el}</div>))}</div>
                                        <Typography.Text
                                            type={item.success ? 'success' : item.checked ? undefined : 'danger'}>
                                            {item.loading ? (
                                                <Progress percent={99.9} status="active" showInfo={false} strokeColor={{ from: '#145cd4', to: '#52c41a' }} />
                                            ) : (
                                                <span>{(item.success ? <CheckCircleOutlined /> : <CloseCircleOutlined />)} {item.message}</span>
                                            )}
                                        </Typography.Text>
                                        {(!item.success) && (!item.checked) && (
                                            <Button size='small' type="primary" onClick={() => {
                                                const index = txReceipList.findIndex(el => el.txId === item.txId)
                                                txReceipList[index] = {
                                                    ...item,
                                                    checked: true
                                                }
                                                setTxReceiptList(txReceipList)
                                            }}>Get it</Button>
                                        )}
                                    </Space>}
                            />
                        </List.Item>
                    )}
                />
            </Drawer>
        </Layout >
    )
}

export default RatifierDashboard