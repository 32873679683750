import * as React from 'react'
import { TronWeb } from '../hooks/useTronHooks';

class NoProviderError extends Error {
    constructor() {
        super('Please install the tronlink application.')
    }
}
class NoConnectError extends Error {
    constructor() {
        super('Please connect the website mannually or refresh current page.')
    }
}

class ConnectProviderError extends Error {
    constructor() {
        super('Please unlock the tronlink wallet.')
    }
}

declare global {
    interface Window { tronWeb: any; }
}

export enum TronNode {
    TronGrid = 'TronGrid',
    TronStack = 'TronStack',
    Shasta = 'Shasta',
    Nile = 'Nile',
    SunMainnet = 'SunMainnet',
    SunTestnet = 'SunTestnet'
}

export function useTronWallet() {
    const [account, setAccount] = React.useState('')
    const [tronNode, setTronNode] = React.useState<string>('')
    const [tronWeb, setTronWeb] = React.useState<TronWeb | null>(null)
    const [isConnected, setIsConnected] = React.useState(false)

    const node = React.useMemo(() => {
        switch (tronNode) {
            case 'https://api.trongrid.io':
                return TronNode.TronGrid
            case 'https://api.tronstack.io':
                return TronNode.TronStack
            case 'https://api.shasta.trongrid.io':
                return TronNode.Shasta
            case 'https://api.nileex.io':
                return TronNode.Nile
            case 'https://sun.tronex.io':
                return TronNode.SunMainnet
            case 'https://suntest.tronex.io':
            case 'https://testhttpapi.tronex.io':
                return TronNode.SunTestnet
            default:
                return null
        }
    }, [tronNode])

    const init = React.useCallback(async () => {
        const tronWebObj = window.tronWeb
        if (!tronWebObj && (typeof tronWebObj !== 'boolean')) {
            throw new NoProviderError()
        }
        if (tronWebObj.defaultAddress === false) {
            throw new NoConnectError()
        }
        if (!tronWeb) {
            setTronWeb(tronWebObj)
        }
        if (!tronNode && tronWebObj.fullNode?.host) {
            console.log('init fullNode', tronWebObj.fullNode.host) // maybe current host
            setTronNode(tronWebObj.fullNode.host)
        }
    }, [setTronWeb, setTronNode])

    const connect = React.useCallback(async () => {
        const tronWebObj = window.tronWeb
        if (!tronWebObj && (typeof tronWebObj !== 'boolean')) {
            throw new NoProviderError()
        }
        if (tronWebObj.defaultAddress === false) {
            throw new NoConnectError()
        }
        let account = tronWebObj.defaultAddress.base58
        if (!account) {
            throw new ConnectProviderError()
        }
        setAccount(account)
        setIsConnected(true)
    }, [setAccount])

    const disconnect = React.useCallback(async () => {
        setAccount('')
        setIsConnected(false)
    }, [setAccount])

    React.useEffect(() => {
        const timeoutId = setTimeout(async () => {
            await init()
        }, 0)
        const listener = (e: any) => {
            if (e.data.message) {
                const message = e.data.message
                const data = message.data
                const action = message.action
                if (action && data) {
                    if (action === 'tabReply' && data.success && data.data.isAuth) {
                        const node = data.data.node
                        if (node) {
                            const fullNode = node.fullNode
                            setTronWeb(window.tronWeb)
                            setTronNode(fullNode)
                            clearTimeout(timeoutId)
                        }
                    } else if (action === 'rejectWeb') {
                        setAccount('')
                    } else if (action == "setAccount" && isConnected) {
                        setAccount(data.address || '')
                    } else if (action == "setNode") {
                        const fullNode = data.node.fullNode || ''
                        setTronNode(fullNode)
                    }
                }
            }
        }
        window.addEventListener('message', listener)
        return () => {
            window.removeEventListener('message', listener)
            clearTimeout(timeoutId)
        }
    }, [setTronNode, setTronWeb, setAccount, isConnected, init])
    return { account, connect, disconnect, node, tronWeb }
}