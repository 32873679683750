import React, { FormEvent } from 'react'
import MInput, { Input, InputProps } from 'antd'
import BigNumber from 'bignumber.js'
import { message } from 'antd'


export function isNumber(text: string) {
    return /^-?[0-9]+\.?[0-9]*$/.test(text)
}

export type InputNumberProps = Omit<InputProps, 'value' | 'defaultValue'> & { precision?: number, min?: number, required?: boolean, value?: string, defaultValue?: string }
const TNumberInput: React.FC<InputNumberProps> = function (props) {
    const { onInput, onChange, precision, onBlur, max, min, required, defaultValue, value, ...restProps } = props
    const [inputVal, setInputVal] = React.useState(defaultValue || '')
    React.useEffect(() => {
        if (value !== undefined) {
            _onInput({ target: { value } } as any as FormEvent<HTMLInputElement>)
        } else {
            _onInput({ target: { value: defaultValue || '' } } as any as FormEvent<HTMLInputElement>)
        }
    }, [value])
    const confirm = () => {
        if (value) {
            if (value === '-') {
                _onInput({ target: { value: '' } } as any as FormEvent<HTMLInputElement>)
                return
            }
            if (max !== undefined && new BigNumber(value).gt(max)) {
                _onInput({ target: { value: max + '' } } as any as FormEvent<HTMLInputElement>)
                return
            }
            if (min !== undefined && new BigNumber(value).lt(min)) {
                _onInput({ target: { value: min + '' } } as any as FormEvent<HTMLInputElement>)
                return
            }
        }
        return value
    }
    const negative = React.useMemo(() => {
        return min === undefined ? true : min < 0
    }, [min])
    const _onInput: React.FormEventHandler<HTMLInputElement> = React.useMemo(() => {
        const inputRegExp = new RegExp(`^(${negative ? '-?' : ''}((((([1-9]\\d*)|0)\\.\\d${typeof precision === 'number' ? `{0,${precision}}` : '*'}))|(([1-9]\\d*)|0)))$`)
        return (e) => {
            let value = (e.target as HTMLInputElement).value
            value = (value || '').trim()
            if (negative && value === '-') {
                setInputVal(value)
                return
            }
            if (value !== '') {
                if (!isNumber(value)) {
                    return
                }
                if (!inputRegExp.test(value)) {
                    return
                }
            }
            setInputVal(value)
            onInput && onInput(Object.assign({}, e, { target: { value } }))
        }
    }, [onInput, setInputVal, precision, negative])
    const _onChange: React.ChangeEventHandler<HTMLInputElement> = React.useMemo(() => {
        const inputRegExp = new RegExp(`^(${negative ? '-?' : ''}((((([1-9]\\d*)|0)\\.\\d${typeof precision === 'number' ? `{0,${precision}}` : '*'}))|(([1-9]\\d*)|0)))$`)
        return (e) => {
            let value = (e.target as HTMLInputElement).value
            value = (value || '').trim()
            if (negative && value === '-') {
                setInputVal(value)
                return
            }
            if (value !== '') {
                if (!isNumber(value)) {
                    return
                }
                if (!inputRegExp.test(value)) {
                    return
                }
            }
            setInputVal(value)
            onChange && onChange(Object.assign({}, e, { target: { value } }))
        }
    }, [onInput, setInputVal, precision, negative])
    return (
        <Input
            {...restProps}
            value={inputVal}
            defaultValue={defaultValue}
            onBlur={(e) => {
                confirm()
                onBlur && onBlur(e)
            }}
            onChange={_onChange}
            onInput={_onInput} />
    )
}
export default TNumberInput