import { TronNode } from "../connectors/tron-wallet";
import { TronWeb } from "../hooks/useTronHooks";

export const TronContracts = {
    [TronNode.TronGrid]: {
        tokenController: 'TMhGR6uq18Grrne6Ezpm4D3RhBjaRCTX6T',
        hkdtTokenController: 'TMhGR6uq18Grrne6Ezpm4D3RhBjaRCTX6T'
    },
    [TronNode.Nile]: {
        tokenController: 'TNx8YV6cbUHzKTKBEYDdtCAk8EVyEHQVsz',
        hkdtTokenController: 'TU7QVrwCAFsUvqcxeNcTvSn9a6uX9ZKxwz'
    },
}

export const Ratifiers = {
    [TronNode.TronGrid]: [
        'TZ3ze83RcbRdzw98mafRD94gAi1y5bh4T6',
        'TRi3CUVmRM87Ux6eWCpTZMorhgRFXuXbXu',
        'THJkCjLtF36wiWoGk9xsm1Vx4XCbmEvXS9'
    ],
    [TronNode.Nile]: [
        "TVq7e4asMPcrsH3wsD1p72ue75GzXtBzj8",
        "TF3S88RUExktvfcJ8JAn8D9AyEauZpF7Xj",
        "TQSQBdD5BrS2AYmwo8WGUS9A24vrxnZHb2",
        "TKAF2jHUtZJaFwnN44CcVkWPV5fHDRRk68",
        "TNvhp4gVMDFPQ8d98TTR461MmrFDvSjfwk",
        "TXnGdmeybLdG36XJvEm9FiXGieLNNCZjNQ"
    ]
}

export const Observers = [
    "TTYymuCMsVEGpGKasgVowppBufuvDGnXaY",
    "TS26jE7NqbfCAx5XrrRd4txhnMDQDdMe5T",
    "TB9UwnLEgHiUaSFdeHhSYMd4nT5k6XRxjJ"
]

export const TronScanPrefixs = {
    [TronNode.TronGrid]: 'https://tronscan.org',
    [TronNode.Nile]: 'https://nile.tronscan.org',
    [TronNode.Shasta]: '',
    [TronNode.TronStack]: '',
    [TronNode.SunMainnet]: '',
    [TronNode.SunTestnet]: ''
}

export const TronAPIHosts = {
    [TronNode.TronGrid]: 'https://api.trongrid.io',
    [TronNode.Nile]: 'https://nile.trongrid.io',
    [TronNode.Shasta]: '',
    [TronNode.TronStack]: '',
    [TronNode.SunMainnet]: '',
    [TronNode.SunTestnet]: ''
}

export const BLACK_HOLE = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
export const BLACK_HOLE1 = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKLxmGkn'
export const BLACK_HOLE2 = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKT76qbH'

export function formatBase58Check(tronWeb: TronWeb, addr: string) {
    if (tronWeb.isAddress(addr)) {
        const start = addr.slice(0, 1).toLowerCase()
        const base58Check = start === 't' ? addr : tronWeb.address.fromHex(addr)
        return base58Check
    }
    return addr
}

export function isBlackHoleAddr(tronWeb: TronWeb, addr: string) {
    const base58Check = formatBase58Check(tronWeb, addr)
    return !![BLACK_HOLE, BLACK_HOLE1, BLACK_HOLE2].find(el => el.toLowerCase() === base58Check.toLowerCase())
}

export const UNWHITELISTED_LIMIT = '1000000000000000000000000';
export const RatifierAttribute: string = '0x697354434e484d696e7452617469666965720000000000000000000000000000';
export const PauserAttribute: string = '0x697354434e484d696e7450617573657273000000000000000000000000000000';

