import {
  BrowserRouter as Router,
} from "react-router-dom";
import { useRoutes } from "react-router-dom";
import RatifierDashboard from './pages/RatifierDashboard'
import HKD from './pages/HKD'
import 'antd/dist/reset.css';


function Routes() {
  let element = useRoutes([
    {
      path: "/",
      children: [
        {
          path: '',
          element: (
            <RatifierDashboard />
          ),
        }
      ],
    },
    {
      path: "/hkd",
      children: [
        {
          path: '',
          element: (
            <HKD />
          ),
        }
      ],
    }
  ]);

  return element;
}

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
