import * as React from 'react'
import { TronNode, useTronWallet } from '../../connectors/tron-wallet'
import { message, Layout, Col, Row, Typography, Button, Dropdown, List, Space, Divider, Form, Input, Radio, Collapse, Popover, Result, FloatButton, Drawer, Skeleton, Avatar, Progress, Tabs } from 'antd'
import InputNumber from '../../components/InputNumber'
import EllipsisMiddle from '../../components/EllipsisMiddle';
const { Header, Footer, Content } = Layout
const { Panel } = Collapse
/* eslint-disable import/first */
import styled from 'styled-components';
import Icon, { WalletOutlined, InfoCircleOutlined, SyncOutlined, FileDoneOutlined, FileExcelOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './index.css'
import { useRatifierContract } from './useRatifierContracts';
import { LoadingOutlined } from '@ant-design/icons'
import BigNumber from 'bignumber.js'
import { TronScanPrefixs } from '../../configs';
import OwnerIcon from '../../icons/owner'
import ExternalIcon from '../../icons/external'
import DisconnnectIcon from '../../images/disconnect.png'
import TronlinkIcon from '../../images/tronlink.png'
import NetworkErrorIcon from '../../icons/network-error'
import startCase from 'lodash/startCase'
import { Rule } from 'antd/es/form';
import { ethers } from 'ethers'
import { Observers } from '../../configs'
import { retry } from '../../utils';

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #145cd4;
    &:hover{
        background-color: #145cd4;
    }
  }
`;
interface TransactionInfo {
    blockNumber: number,
    blockTimeStamp: number,
    contractResult: string[],
    contract_address: string,
    fee: number,
    id: number,
    receipt?: {
        energy_fee: number,
        energy_usage_total: number,
        net_usage: number,
        result: string
    },
    resMessage: string,
    result: "SUCCESS" | "FAILED"
}


function getRoleColor(role: string) {
    let color = '#333333'
    if (role === 'owner') {
        color = '#31ADCE'
    } else if (role === 'ratifier') {
        color = '#2CD723'
    } else if (role === 'mintKey') {
        color = '#C7DB2D'
    } else if (role === 'registryAdmin') {
        color = '#F17D10'
    } else if (role === 'registryOwner') {
        color = '#e37fe1'
    } else if (role === 'observer') {
        color = '#e3e3e3'
    }
    return color
}

function Operators(el: { label: string, permissions: string[], isHeader?: boolean }) {
    return (
        <Space className='center'>
            <span className={el.isHeader ? 'header-4' : undefined}>{el.label}</span>
            <Popover placement='top' content={(
                <Space>
                    <Typography.Text>{el.permissions.length > 1 ? 'Operators' : 'Operator'} :</Typography.Text>
                    <span>
                        <Typography.Text>{startCase(el.permissions[0])}</Typography.Text>
                        <span> {el.permissions.length === 2 ? '&' : ''} </span>
                        <Typography.Text>{startCase(el.permissions[1])}</Typography.Text>
                    </span>
                </Space>
            )}>
                <InfoCircleOutlined />
            </Popover>
        </Space>
    )
}

interface TxRecord { txId: string, success: boolean, message: string, loading: boolean, timeout: boolean, checked: boolean, meta: string[] }
const globalTxReceipList: TxRecord[] = []

const RatifierDashboard: React.FC<{}> = function () {
    const [isDrawerOpen, setDrawerOpen] = React.useState(false)
    const [txReceipList, setTxReceiptList] = React.useState<TxRecord[]>([]) // use globalTxReceipList, because of the array update
    const { account, node, connect, disconnect, tronWeb } = useTronWallet()
    const { tokenController, isLoadError, requestData, config } = useRatifierContract(tronWeb, node === TronNode.Nile ? TronNode.Nile : TronNode.TronGrid, account)
    let { tokenConfig, owner, pendingOwner, paused, mintPaused } = config


    const sign = async function (fn: { <T>(): Promise<string> }, meta: string[], success?: () => void) {
        try {
            const tx = await fn()
            globalTxReceipList.push({
                txId: tx,
                message: '',
                loading: true,
                timeout: false,
                checked: false,
                success: true,
                meta
            })
            setTxReceiptList([...globalTxReceipList])
            message.success('Transaction has been submitted, the execution result will return later.')
            // Don't await
            retry(async () => {
                if (tronWeb) {
                    const txInfo: TransactionInfo = await tronWeb.trx.getTransactionInfo(tx)
                    if (!txInfo.receipt) {
                        throw new Error('Get the transaction receipt is timeout.')
                    }
                    return txInfo
                }
                return null
            }, 10000, 20).then((txInfo) => {
                if (txInfo && txInfo.receipt) {
                    const isSuccess = txInfo.receipt.result === 'SUCCESS'
                    const resMessage = txInfo.receipt.result
                    const contractCallResult = txInfo.contractResult.length > 0 ? txInfo.contractResult.map(result => result ? tronWeb.toUtf8(`0x${result.slice(8)}`) : 'Failed').join(';') : ''
                    if (isSuccess) {
                        message.success('Transaction has been executed successfully.')
                        if (success) {
                            requestData()
                            success()
                        }
                    } else {
                        message.error('Transaction execute failed, Please check the result.')
                    }
                    const index = globalTxReceipList.findIndex(el => el.txId === tx)
                    globalTxReceipList[index] = {
                        txId: tx,
                        success: isSuccess,
                        message: isSuccess ? 'Success' : contractCallResult || resMessage,
                        loading: false,
                        timeout: false,
                        checked: false,
                        meta
                    }
                    setTxReceiptList([...globalTxReceipList])
                }
            }).catch(e => {
                const index = globalTxReceipList.findIndex(el => el.txId === tx)
                globalTxReceipList[index] = {
                    txId: tx,
                    success: false,
                    message: (e as Error).message,
                    loading: false,
                    timeout: true,
                    checked: false,
                    meta
                }
                setTxReceiptList([...globalTxReceipList])
            })
        } catch (err) {
            const e = err as Error
            message.error(e.message ? e.message : String(e as any))
        }
    }

    let isTargetTronNode = node === process.env.REACT_APP_TRON_NODE
    let isObserver = !!Observers.find(o => o.toLowerCase() === account.toLowerCase())
    let isOwner = owner ? owner.toLowerCase() === account.toLowerCase() : undefined
    isOwner = process.env.NODE_ENV === 'development' ? true : isOwner
    const isPendingOwner = process.env.NODE_ENV === 'development' ? true : (pendingOwner ? pendingOwner.toLowerCase() === account.toLowerCase() : false)
    const decimals = tokenConfig?.decimals || 18
    const totalSupply = tokenConfig?.totalSupply || new BigNumber(0)
    const isUser = isOwner || isObserver || isPendingOwner
    const isLoading = !(tokenConfig && config && (typeof owner === 'string'))

    const addrRules: Rule[] = [
        {
            required: true,
            message: "${name} is required."
        },
        {

            validator: async function (rule, value) {
                if (!(tronWeb && tronWeb.isAddress(value))) {
                    throw new Error('Invalid address.')
                }
            }
        },
    ]

    const amountRules: Rule[] = [
        {
            required: true,
            message: "${name} is required."
        },
        {

            validator: async function (rule, value) {
                if (new BigNumber(value).lte(0)) {
                    throw new Error('Amount should be greater than 0.')
                }
            }
        },
    ]


    return (
        <Layout className='fill-height' style={{ height: '100%' }}>
            <Header>
                <Row wrap={false} align="middle">
                    <Col flex="auto">
                        <Typography.Title level={2} style={{ color: '#ffffff', marginBottom: 0 }}>
                            HKDT Internal Dashboard
                        </Typography.Title>
                    </Col>
                    <Col flex="none">
                        {account ? (
                            <Space style={{ display: 'flex', alignItems: 'center', color: '#ffffff' }} className='center'>
                                <Dropdown
                                    trigger={['click']}
                                    overlayStyle={{ minWidth: 'auto!important' }}
                                    menu={{
                                        items: [{
                                            key: '4',
                                            label: (
                                                <Space onClick={async () => await disconnect()}>
                                                    <span>Disconnect</span>
                                                    <img src={DisconnnectIcon} style={{ height: '1em' }} />
                                                </Space>
                                            ),
                                        }]
                                    }}>
                                    <Space>
                                        <EllipsisMiddle suffixCount={10} style={{ maxWidth: '200px', color: 'inherit', cursor: 'pointer' }}>
                                            {account}
                                        </EllipsisMiddle>
                                        <span>|</span>
                                        <span>{node === TronNode.TronGrid ? 'Mainnet' : node}</span>
                                    </Space>
                                </Dropdown>
                                <a target={'_blank'} href={node ? `${TronScanPrefixs[node]}/#/address/${account}` : undefined} style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                                    <Icon component={ExternalIcon} style={{ color: '#ffffff' }} />
                                </a>
                            </Space>
                        ) : (
                            <ButtonContainer>
                                <Button type='primary' onClick={async () => {
                                    try {
                                        await connect()
                                    } catch (e) {
                                        message.error((e as Error).message)
                                    }
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Connect</span>
                                        <img src={TronlinkIcon} style={{ height: '1em', marginLeft: '5px' }} />
                                    </div>
                                </Button>
                            </ButtonContainer>
                        )}

                    </Col>
                </Row>
            </Header>
            <Content>
                {account ? (
                    isTargetTronNode ? (
                        isLoadError ? (
                            <Result
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%', flex: 'auto' }}
                                title={<Typography.Title level={4}>Request failed.</Typography.Title>}
                                extra={(<Space style={{ fontSize: '16px' }}>Please <Button size='small' onClick={requestData}>reload</Button> the data.</Space>)} />
                        ) : isLoading ? (
                            <Result
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%', flex: 'auto' }}
                                icon={<LoadingOutlined />}
                            />
                        ) : (
                            isUser ? (
                                <>
                                    <div className='panel' style={{ padding: '20px', marginTop: '20px' }}>
                                        <h4 className='header-4'>
                                            User Role & Permissions
                                        </h4>
                                        <Space size={'large'} style={{ margin: '10px 0' }}>
                                            {isOwner && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('owner'), width: '20px', height: '20px' }} /><span className='role-name'>Owner</span></Space>)}
                                            {isObserver && (<Space ><Icon component={OwnerIcon} style={{ color: getRoleColor('observer'), width: '20px', height: '20px' }} /><span className='role-name'>Observer</span></Space>)}
                                        </Space>
                                    </div>
                                    <div className='panel' style={{ padding: '20px' }}>
                                        <h3>Current total supply</h3>
                                        <Typography>
                                            HK ${totalSupply.toFixed(2, BigNumber.ROUND_FLOOR)}
                                        </Typography>
                                    </div>
                                    <div className='tabs-container' style={{ padding: '20px' }}>
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    key: '1',
                                                    label: `Mint`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4 className='header-4'>
                                                                    Mint Token
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            const amount = ethers.utils.parseUnits(values.amount, decimals)
                                                                            await sign(() => tokenController.mint<string>(values.receipt, amount).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Mint', `Receipt: ${values.receipt}`, `Amount: ${values.amount}`], () => {
                                                                                requestData()
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <Form.Item name="receipt" label="Receipt" rules={addrRules}>
                                                                        <Input placeholder={`Receipt address`} />
                                                                    </Form.Item>
                                                                    <Form.Item name="amount" label="Amount" rules={amountRules}>
                                                                        <InputNumber precision={decimals} style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                    <Form.Item label={' '} >
                                                                        <Button type="primary" htmlType="submit">Submit</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4 className='header-4'>
                                                                    Burn Token
                                                                </h4>
                                                                <Typography.Text>
                                                                    Only burning the owner's funds.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            const amount = ethers.utils.parseUnits(values.amount, decimals)
                                                                            await sign(() => tokenController.burn<string>(amount).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Burn', `Receipt: ${values.receipt}`, `Amount: ${values.amount}`], () => {
                                                                                requestData()
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <Form.Item name="amount" label="Amount" rules={amountRules}>
                                                                        <InputNumber precision={decimals} style={{ width: '100%' }} />
                                                                    </Form.Item>
                                                                    <Form.Item label={' '} >
                                                                        <Button type="primary" htmlType="submit">Submit</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: '3',
                                                    label: `Role`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Set Blacklist'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            const address = values.address
                                                                            const isBlacklisted = await tokenController.isBlacklisted<boolean>(address).call()
                                                                            if (isBlacklisted === values.blacklist) {
                                                                                message.error(isBlacklisted ? `${address} is already in blacklist.` : `${address} is not in blacklist.`)
                                                                                return
                                                                            }
                                                                            if (values.blacklist) {
                                                                                await sign(() => tokenController.blacklist<string>(values.address).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Set blacklist', `Address: ${values.address}`])
                                                                            } else {
                                                                                await sign(() => tokenController.unBlacklist<string>(values.address).send({
                                                                                    from: account,
                                                                                    gas: 300000
                                                                                }), ['Remove blacklist', `Address: ${values.address}`])
                                                                            }
                                                                        }
                                                                    }}
                                                                    colon={false}>
                                                                    <>
                                                                        <Form.Item label="Address" name="address" rules={addrRules}>
                                                                            <Input />
                                                                        </Form.Item>
                                                                        <Form.Item name="blacklist" label="Blacklist" rules={[{ required: true, message: 'Please check the can burn field.' }]}>
                                                                            <Radio.Group>
                                                                                <Radio value={true}>Add</Radio>
                                                                                <Radio value={false}>Remove</Radio>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                        <Form.Item label={' '}>
                                                                            <Button type="primary" htmlType="submit">Sumbit</Button>
                                                                        </Form.Item>
                                                                    </>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Transfer Ownership'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    requiredMark={false}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async (values) => {
                                                                        if (tokenController) {
                                                                            await sign(() => tokenController.transferOwnership<string>(values.address).send({
                                                                                from: account,
                                                                                gas: 300000
                                                                            }), ['Transfer Ownership', `New Owner: ${values.address}`])
                                                                        }
                                                                    }}>
                                                                    <Form.Item label="Address" name="address" rules={addrRules}>
                                                                        <Input />
                                                                    </Form.Item>
                                                                    <Form.Item label={' '}>
                                                                        <Button type="primary" htmlType="submit">Sumbit</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            {isPendingOwner && (
                                                                <div className='panel' style={{ padding: '20px' }}>
                                                                    <h4>
                                                                        <Operators label={'Accept Ownership'} permissions={['pending owner']} isHeader />
                                                                    </h4>
                                                                    <Divider />
                                                                    <Form
                                                                        disabled={!isOwner || !isObserver}
                                                                        requiredMark={false}
                                                                        labelCol={{ span: 2 }}
                                                                        colon={false}
                                                                        layout={'horizontal'}>
                                                                        <Form.Item label={''}>
                                                                            <Button type='primary' onClick={async () => {
                                                                                if (tokenController) {
                                                                                    await sign(() => tokenController.acceptOwnership<string>().send({
                                                                                        from: account,
                                                                                        gas: 300000
                                                                                    }), ['Accept Ownership'])
                                                                                }
                                                                            }}>Accept</Button>
                                                                        </Form.Item>
                                                                    </Form>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    key: '4',
                                                    label: `Emergency`,
                                                    children: (
                                                        <div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Pause Mint'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Typography.Text>
                                                                    If there is a continuous stream of invalid mint requests, owner can pause all of them. Only owner can unpause mint.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async () => {
                                                                        if (tokenController) {
                                                                            if (mintPaused) {
                                                                                await sign(() => tokenController.unpauseMint<string>().send({
                                                                                    from: account,
                                                                                    gas: 200000
                                                                                }), ['Resume all mints'])
                                                                            } else {
                                                                                await sign(() => tokenController.pauseMint<string>().send({
                                                                                    from: account,
                                                                                    gas: 200000
                                                                                }), ['Pause all mints'])
                                                                            }
                                                                        }
                                                                    }}>
                                                                    <Form.Item label={''} >
                                                                        <Button type="primary" htmlType='submit' danger>{mintPaused ? 'Resume' : 'Pause'}</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                            <div className='panel' style={{ padding: '20px' }}>
                                                                <h4>
                                                                    <Operators label={'Pause Transfer'} permissions={['Owner']} isHeader />
                                                                </h4>
                                                                <Typography.Text>
                                                                    If there is a continuous stream of invalid transfer requests, owner can pause all of them. Only owner can unpause transfer.
                                                                </Typography.Text>
                                                                <Divider />
                                                                <Form
                                                                    disabled={!isOwner}
                                                                    labelCol={{ span: 2 }}
                                                                    colon={false}
                                                                    layout={'horizontal'}
                                                                    onFinish={async () => {
                                                                        if (tokenController) {
                                                                            if (paused) {
                                                                                await sign(() => tokenController.unpause<string>().send({
                                                                                    from: account,
                                                                                    gas: 200000
                                                                                }), ['Resume all Transfers'])
                                                                            } else {
                                                                                await sign(() => tokenController.pause<string>().send({
                                                                                    from: account,
                                                                                    gas: 200000
                                                                                }), ['Pause all Transfers'])
                                                                            }
                                                                        }
                                                                    }}>
                                                                    <Form.Item label={''} >
                                                                        <Button type="primary" htmlType='submit' danger>{paused ? 'Resume' : 'Pause'}</Button>
                                                                    </Form.Item>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                            ]} />
                                    </div>
                                </>
                            ) : (
                                <Result
                                    className='result'
                                    status="warning"
                                    title="You are not a authorized user."
                                />
                            )
                        )
                    ) : (
                        <Result
                            className='result'
                            icon={<Icon component={NetworkErrorIcon} style={{ fontSize: '50px', marginBottom: '20px' }} />}
                            extra={
                                <Typography.Title level={3}>Please switch the network to {(process.env.REACT_APP_TRON_NODE || '').toLowerCase()}.</Typography.Title>
                            }
                        />
                    )
                ) : (
                    <Result
                        className='result'
                        icon={<WalletOutlined style={{ fontSize: '50px', marginBottom: '20px' }} />}
                        extra={
                            <Space direction='vertical'>
                                <Typography.Title level={3}>Please connect with authorized tronlink wallets.</Typography.Title>
                                {node && (
                                    <Skeleton active loading={!owner} title={false} avatar={false} paragraph={{ rows: 1, width: '100%' }}>
                                        <Typography.Text>Contract is owned by <Typography.Link target={'_blank'} href={`${TronScanPrefixs[node]}/#/address/${owner || Observers[0]}`}>{owner || Observers[0]}</Typography.Link></Typography.Text>
                                    </Skeleton>
                                )}
                            </Space>
                        }
                    />
                )}
            </Content>
            <Footer>
                <div className='footer'>
                    © 2023 HKDT. All rights reserved.
                </div>
            </Footer>
            {txReceipList.length > 0 && (
                <FloatButton
                    icon={txReceipList.find(tx => tx.loading) ? <SyncOutlined spin style={{ color: '#145cd4', fontSize: '20px' }} /> : (txReceipList.find(tx => !tx.success && !tx.checked) ? <FileExcelOutlined style={{ color: '#eb2f96', fontSize: '20px' }} /> : <FileDoneOutlined style={{ color: '#52c41a', fontSize: '20px' }} />)}
                    style={{ bottom: '100px', width: '60px', height: "60px" }}
                    onClick={() => { setDrawerOpen(true) }}
                    tooltip={<div>{txReceipList.find(tx => tx.loading) ? 'Pending transactions' : txReceipList.find(tx => !tx.success && !tx.checked) ? 'Some transactions executed failed.' : 'All Transactions has been checked.'}</div>} />
            )}
            <Drawer
                title="Transaction Receipts"
                placement={'right'}
                closable={true}
                onClose={() => setDrawerOpen(false)}
                open={isDrawerOpen}
            >
                <List
                    itemLayout="vertical"
                    dataSource={[...txReceipList].reverse()}
                    renderItem={(item, index) => (
                        <List.Item
                            key={item.txId}
                            style={{ padding: '12px 0 0 0' }}>
                            <List.Item.Meta
                                avatar={(<Avatar>{txReceipList.length - index}</Avatar>)}
                                title={<Typography.Text>Tx: <Typography.Link href={node ? `${TronScanPrefixs[node]}/#/transaction/${item.txId}` : undefined} target={'_blank'}>{item.txId.slice(0, 23) + '...'}</Typography.Link></Typography.Text>}
                                description={
                                    <Space direction="vertical" style={{ marginBottom: '10px', display: 'flex' }}>
                                        <div style={{ lineHeight: 1.5 }}>{item.meta.map(el => (<div>{el}</div>))}</div>
                                        <Typography.Text
                                            type={item.success ? 'success' : item.checked ? undefined : 'danger'}>
                                            {item.loading ? (
                                                <Progress percent={99.9} status="active" showInfo={false} strokeColor={{ from: '#145cd4', to: '#52c41a' }} />
                                            ) : (
                                                <span>{(item.success ? <CheckCircleOutlined /> : <CloseCircleOutlined />)} {item.message}</span>
                                            )}
                                        </Typography.Text>
                                        {(!item.success) && (!item.checked) && (
                                            <Button size='small' type="primary" onClick={() => {
                                                const index = globalTxReceipList.findIndex(el => el.txId === item.txId)
                                                globalTxReceipList[index] = {
                                                    ...item,
                                                    checked: true
                                                }
                                                setTxReceiptList([...globalTxReceipList])
                                            }}>Get it</Button>
                                        )}
                                    </Space>}
                            />
                        </List.Item>
                    )}
                />
            </Drawer>
        </Layout >
    )
}

export default RatifierDashboard