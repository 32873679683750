import React from "react";
import { TronContracts } from "../../configs";
import { TronNode } from "../../connectors/tron-wallet";
import { TronWeb, useTronContract } from "../../hooks/useTronHooks";
import TokenControllerABI from '../../abis/tron/HKDT.json'
import BigNumber from "bignumber.js";

export function useRatifierContract(tronWeb: TronWeb, node: TronNode.TronGrid | TronNode.Nile, account: string) {
    const tokenController = useTronContract(tronWeb, TronContracts[node].hkdtTokenController, TokenControllerABI)
    const [otherConfig, setOtherConfig] = React.useState<{
        tokenConfig: {
            decimals: number,
            name: string,
            symbol: string,
            totalSupply: BigNumber
        },
        owner: string,
        pendingOwner: string,
        mintPaused: boolean,
        paused: boolean
    } | null>(null)
    const [isLoadError, setIsLoadError] = React.useState(false)

    const requestOnce = React.useCallback(async () => {
        try {
            setIsLoadError(false)
            if (tokenController) {
                const tokenControllerOwner = await tokenController.owner<string>().call()
                const pendingOwner = await tokenController.pendingOwner<string>().call()
                const name = await tokenController.name<string>().call()
                const decimals = await tokenController.decimals<number>().call()
                const symbol = await tokenController.symbol<string>().call()
                const totalSupply = await tokenController.totalSupply<string>().call()
                const mintPaused = await tokenController.mintPaused<boolean>().call()
                const paused = await tokenController.paused<boolean>().call()
                setOtherConfig({
                    tokenConfig: {
                        decimals,
                        name,
                        symbol,
                        totalSupply: new BigNumber(totalSupply.toString()).div(new BigNumber(10).pow(decimals))
                    },
                    owner: tronWeb.address.fromHex(tokenControllerOwner),
                    pendingOwner: tronWeb.address.fromHex(pendingOwner),
                    mintPaused,
                    paused
                })
            }
        } catch (e) {
            setIsLoadError(true)
        }

    }, [tokenController, account, tronWeb, setOtherConfig, setIsLoadError])

    React.useEffect(() => {
        requestOnce()
    }, [requestOnce])

    return {
        tokenController,
        isLoadError,
        requestData: requestOnce,
        config: {
            ...otherConfig
        }
    }
}


