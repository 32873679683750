import { Col, Progress, Row, Typography, TypographyProps } from "antd"
import { HTMLProps, PropsWithChildren } from "react"
import { BigNumber } from 'bignumber.js'

// TODO
function percentileTextColor(pool?: { mint: BigNumber, limit: BigNumber }) {
    const props: { disabled: boolean, type: 'success' | 'warning' | 'danger' } = {
        disabled: !pool,
        type: 'success'
    }
    if (pool) {
        const { mint, limit } = pool
        if (mint.lte(limit.div(6))) {
            props.type = 'success'
        } else if (mint.lte(limit.div(2))) {
            props.type = 'warning'
        } else {
            props.type = 'danger'
        }
    }
    return props
}

function formatCurrency(decimals: number, pool?: { mint: BigNumber, limit: BigNumber }, direction?: "horizontal" | "vertical") {
    if (!pool) {
        return '--'
    }
    const { mint, limit } = pool
    return (
        <div style={direction === 'horizontal' ? { display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' } : {}}>
            <div style={{ color: '#222222' }}>{`¥ ${mint.div(new BigNumber(10).pow(decimals)).dp(2).toFormat()}`}</div>
            {direction === 'horizontal' && (<span> / </span>)}
            <div style={{ fontSize: '12px' }}>{`¥ ${limit.div(new BigNumber(10).pow(decimals)).dp(2).toFormat()}`} Cap</div>
        </div>
    )
}

const PoolPercent: React.FC<{ decimals: number, direction?: "horizontal" | "vertical", pool?: { mint: BigNumber, limit: BigNumber }, style?: any }> = function ({ pool, decimals, direction, ...restProps }) {
    return (
        <Row gutter={24} align={'middle'} justify={'space-between'}>
            <Col flex={'auto'}>
                <Typography.Text {...restProps} {...percentileTextColor()}>{formatCurrency(decimals, pool, direction)}</Typography.Text>
            </Col>
            {pool && (
                <Col flex={'none'}>
                    <Progress type="circle" className="pool-progress" percent={pool.limit.eq(0) ? 0 : pool.mint.div(pool.limit).times(100).dp(0, BigNumber.ROUND_FLOOR).toNumber()} width={36} format={(percent) => `${percent}%`} />
                </Col>
            )}
        </Row>
    )
}
export default PoolPercent